import(/* webpackMode: "eager" */ "/opt/workspace/aelf-example/node_modules/antd/dist/antd.css");
;
import(/* webpackMode: "eager" */ "/opt/workspace/aelf-example/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/workspace/aelf-example/src/pageComponents/layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/workspace/aelf-example/src/provider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/workspace/aelf-example/src/styles/base.css");
;
import(/* webpackMode: "eager" */ "/opt/workspace/aelf-example/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/opt/workspace/aelf-example/src/styles/theme.css");
